
<template>
  <span class="location">
    <div id="amap"></div>
    <i @click="pick">
      <template v-if="showPicker">【确定】</template>
      <template v-else>
        <img src="@/assets/designer/location.png" alt="定位" />
        {{city ? `${city.region_name}【切换】`: '选择城市'}}
      </template>
    </i>
    <template v-if="showPicker">
      <el-select 
        v-model="province_id" 
        size="mini" 
        placeholder="全部" 
        @change="getRegions"
      >
        <el-option v-for="province in provinces" :key="province.id" :label="province.region_name"
          :value="province.region_id"></el-option>
      </el-select>
      <el-select 
        v-model="city_id" 
        size="mini" 
        placeholder="全部" 
        :disabled="!province_id" 
        @change="cityChange"
      >
        <el-option 
          v-for="cityOption in citys" 
          :key="cityOption.id" 
          :label="cityOption.region_name" 
          :value="cityOption.region_id"
          :disabled="city && cityOption.region_id == city.region_id"
        >
        </el-option>
      </el-select>
    </template>
  </span>
  </span>
</template>

<script>
import {
  getRegions
} from '@/api/common'
export default {
  name: 'Location',
  data() {
    let _this = this;
    return {
      showPicker: false,
      provinces:[],
      citys:[],
      province_id: null,
      city_id: null,
      map: null,
    };
  },
  computed: {
    city(){
      return this.$store?.state?.user?.city;
    }
  },
  methods: {
    setMap() {
      // 创建地图
      const _this = this;
      const map = new AMap.Map('amap',{resizeEnable: true});
      map.on('complete', function(){
        console.log('map.on complete'); // 地图图块加载完成后触发
        _this.map = map;
      });
      map.plugin('AMap.CitySearch', function () {
        var citySearch = new AMap.CitySearch()
        citySearch.getLocalCity(function (status, result) {
          console.log('getLocalCity',status, result);
          if (status === 'complete' && result.info === 'OK') {
            console.log('AMap.CitySearch', result);
            // 查询成功，result即为当前所在城市信息
            // _this.city = result.city
            _this.getCity(result.city)
          }
        })
      })
    },
    pick(){
      this.showPicker = !this.showPicker;
      if (this.showPicker) { // 显示切换选择
        this.getRegions();
      } else { // 确定
        this.cityChange(this.city_id);
      }
    },
    async getCity(keyword){
      try {
        const res = await getRegions({
          region_type: 2,
          keyword
        })
        const city = res?.data?.list?.[0]
        if (city) {
          this.$store.dispatch('user/setCity', res.data.list[0]);
          this.province_id = city.region_parent_id;
          this.city_id = city.region_id;
        }
      } catch (error) {
        console.log('error getCity', error)
        this.$message.error(error.message || error)
      }
    },
    async getRegions() {
      // this.loading = true
      try {
        const params = {
          page_size: 0,
          region_type: 1
        }
        if (this.province_id) {
          params.region_parent_id = this.province_id
          params.region_type = 2
        }
        console.log('params', params)
        const res = await getRegions(params)
        console.log('res', res)
        if (this.province_id) {
          this.citys = res.data.list;
          this.city_id = null;
        } else {
          this.provinces = res.data.list
        }
      } catch (error) {
        console.log('error getRegions', error)
        this.$message.error(error.message || error)
      } finally {
      }
    },
    cityChange(city_id){
      console.log('cityChange', city_id);
      const setCity = this.citys.find(city => city.region_id == (city_id || this.city?.region_id)) || this.citys[0];
      this.$store.dispatch('user/setCity',setCity)
    },
  },
  created() {
    this.setMap();
    this.getRegions();
  },
  destroyed() {
    if (this.map) {
      this.map.destroy();
    }
  }
};
</script>
<style lang="less" scoped>
span.location{
  width: fit-content;
  display: inline-block;
  margin: 0 10px;
  #amap{
    width: 1px;
    height: 1px;
    position: absolute;
    opacity: 0;
  }
  >i{
    color: #fad9c7;
    font-size: 14px;
    font-style: normal;
    cursor: pointer;
    img{
      margin: 0 5px 0 0;
    }
  }
  .el-select{
    width: 100px;
    margin: 0 0 0 5px;
    height: 20px;
    ::v-deep {
      .el-input{
          height: 20px;
          line-height: 20px;
        .el-input__inner{
          margin: 0;
          height: 20px;
          line-height: 20px;
        }
        .el-input__suffix{
          height: 20px;
          line-height: 20px;
        }
        .el-input__icon{
          line-height: 20px;
        }
      }
    }
  }
}
</style>