<template>
  <div class="top-bar-container">
    <!--头部nav的引入 -->
    <header class="sub_nav cell">
      <div class="container">
        <span class="welcome">金舵欢迎您！</span>
        <!-- {empty name="$user"} -->
        <template v-if="!token">
          <span class="login_register">
            <a class="loginBtn" href="javascript:void(0);" @click="openLogin"
              >登录</a
            >
            <a
              class="registeredBtn"
              href="javascript:void(0);"
              @click="openRegister"
              >免费注册</a
            >
          </span>
        </template>
        <!-- {else/} -->
        <!-- {if condition="$user['user_name'] != null"} -->
        <template v-else>
          <span class="login_complete">
            <a href="javascript:void(0);" class="already_login">{{user_name || user_nickname}}</a>
            <a
              href="#"
              class="already_login"
              target="_blank"
              v-if="showArticleEdit"
              >文章编辑</a
            >
            <a class="logout" href="javascript:void(0);" @click="logout"
              >退出</a
            >
          </span>
          <!-- {else/} -->
        </template>
        <!-- {/if} -->
        <!-- {/empty} -->
        <Location />
        <nav>
          <span v-if="isDesigner">
            <el-dropdown @command="dropdownPick">
              <span class="el-dropdown-link">
                <!-- {{designer.user_name}}<i class="el-icon-arrow-down el-icon--right"></i> -->
                设计师后台<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="我的方案">我的方案</el-dropdown-item>
                <el-dropdown-item command="我的作品">我的作品</el-dropdown-item>
                <el-dropdown-item command="顾客方案">顾客方案</el-dropdown-item>
                <el-dropdown-item command="顾客预约单">顾客预约单</el-dropdown-item>
                <el-dropdown-item command="个人资料">个人资料</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
          <span>
            <a href="https://auth.jinduo.com/" @click="checkedLogin('/form/cart')">商家后台</a>
          </span>
          <span>
            <router-link to="/download"> 经销商专区 </router-link>
          </span>
          <!-- <span class="oldIndex"><a href="javascript:void(0)" onClick="alert('系统维护中，稍后开放')" target="_blank">旧版金舵官网</a></span> -->
          <!-- <span class="oldIndex"><a href="http://47.112.14.37:8080" target="_blank">旧版金舵官网</a></span> -->

          <!-- <span>
            <a href="javascript:void(0)" @click="checkedLogin('/form/cart')"
              >装修清单</a
            >
          </span> -->
          <span class="personal_center">
            <a href="javascript:void(0)" @click="checkedLogin('/personal')"
              >个人中心</a
            >
          </span>
          <span class="phone_jd" data-open="weixinModal">
            <a href="javascript:void(0)">手机金舵</a>
          </span>
          <!--      <span>-->
          <!--        <a href="#">中文</a>-->
          <!--        <span>│<span>-->
          <!--          <a href="#">EN</a>-->
          <!--      </span>-->
        </nav>
        <!-- 搜索栏 产品搜索 使用header_flag来判断index的搜索栏-->
        <span class="search_bar">
          <el-select v-model="searchType" size="mini">
            <el-option
              v-for="type in ['设计案例','产品','文章']"
              :key="type"
              :label="type"
              :value="type"
            />
          </el-select>
          <input v-model="inputKeyword" class="search_input" type="text" name="inputKeyword" placeholder="请输入内容" @keyup.enter="search">
          <i slot="append" class="iconfont search-product" @click="search">&#xe644;</i>
        </span>
      </div>
    </header>
    <!-- 手机金舵微信二维码模态框 -->
    <section
      class="reveal"
      id="weixinModal"
      data-reveal
      data-close-on-click="false"
      data-animation-in="hinge-in-from-middle-y"
      data-animation-out="hinge-out-from-middle-y"
      data-reset-on-close="true"
    >
      <div class="qrCode" style="background-color: url()">
        <img src="../../assets/wx_qrCode.png" alt="" />
      </div>
      <div class="qrCode_tip">微信扫一扫，进入小程序</div>
      <button
        class="close-button"
        data-close
        aria-label="Close modal"
        type="button"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { nav } from "@/js/nav.js";
import { login } from "@/js/login.js";
import { getAccount } from "@/utils/auth";
import Location from "@/components/Location";

export default {
  name: "topBar",
  components: {
    Location,
  },
  data() {
    return {
      isRegister: false,
      inputKeyword: '',
      searchType: '设计案例',
    };
  },
  computed: {
    ...mapState("user", ["showConfirmModal", "token", "keyword", 'user_role', 'user_name','user_nickname','designer']),
    showArticleEdit() {
      return this.user_name === "jinduo1993";
    },
    isDesigner(){
      return this.$store?.state?.user?.designer?.designer_detail?.id;
    },
  },
  watch: {
    isDesigner(){
      this.checkDesignerInfo()
    },
    keyword() {
      this.inputKeyword = this.keyword;
    },
  },
  created() {
    let flag =
      this.$route.path.includes("/product/list")
    if (!flag) {
      this.inputKeyword = "";
      this.$store.commit("user/CHANGE_KEYWORD", "");
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.inputKeyword = this.keyword;
      // $('body').foundation();
      nav();
      // login()
    });
  },

  methods: {
    checkDesignerInfo(){
      if (this.$store.state.user?.designer?.designer_detail?.id) {
        const {
          user_head,
          user_nickname,
          address_province,
          address_city,
          address_area,
          designer_detail: { 
            work_age,
            rel_service_types,
            rel_styles,
            self_introduce
          }
        } = this.$store.state.user?.designer||{};
        if (
          user_head &&
          user_nickname &&
          address_province &&
          address_city &&    
          work_age &&
          self_introduce &&
          rel_service_types && rel_service_types.length && 
          rel_styles && rel_styles.length
        ) {
          console.log('设计师资料完善');
        } else {
          this.$message('请先完善设计师资料')
          this.$router.push('/designerCenter/settings/info')
        }
      }
    },
    // 打卡登录确认对话框
    openLogin() {
      // console.log("openLogin");
      //this.isRegister = false
      this.$store.commit("user/SWITCH_LOGIN", !this.showConfirmModal);
    },
    async logout() {
      try {
        const res = await this.$store.dispatch("user/logout");
        console.log('logout', res);
        this.$router.push('/')
      } catch (error) {
        console.log('error logout', error);
      } finally {
        window.location.reload()
      }

    },
    // 检验是否已经登录
    checkedLogin(url) {
      if (this.token) {
        this.$router.push(url);
      } else {
        this.openLogin();
        // $('.loginBtn').trigger('click');
      }
    },

    openRegister() {
      this.$store.commit("user/SWITCH_LOGIN", !this.showConfirmModal);
      this.$store.commit("user/CHECK_REGISTER");
      //this.isRegister = true
    },
    // 搜索
    search() {
      console.log('search',this.searchType);
      this.$store.commit("user/CHANGE_KEYWORD", this.inputKeyword.trim());
      switch (this.searchType) {
        case '产品':
          this.$router.push({
            path: `/product/list/0-0-0-0-0/`,
            query:  this.inputKeyword.trim() ? { keywords: this.inputKeyword } : {},
          });
          break;
        case '文章':
          this.$router.push({
            path: `/bbs/searchResult`,
            query: this.inputKeyword.trim() ? { keywords: this.inputKeyword } : {},
          });
          break;
        case '设计案例': default:
          this.$router.push({
            path: `/albums/0-0-0-0-0-0-0-0/`,
            query: this.inputKeyword.trim() ? { keyword: this.inputKeyword } : {},
          });
          break;
      }
    },
    dropdownPick(command){
      // this.$message('click on item ' + command);
      switch (command) {
        case '我的方案':
          this.$router.push(`/designerCenter`)
          break;
        case '我的作品':
          this.$router.push(`/designerCenter/myAlbum`)
          break;
        case '顾客方案':
          // this.$message('该功能暂未开放')
          this.$router.push(`/designerCenter/clientScheme`)
          break;
        case '顾客预约单':
          // this.$message('该功能暂未开放')
          this.$router.push(`/designerCenter/reservation/list`)
          break;
        case '个人资料':
          this.$router.push(`/designerCenter/settings`)
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style lang="less" scoped>
 // 主题颜色
 @theme_color: #42434b;
 // 字体颜色
 @font_color: #fad9c7;
 // hover颜色
 @hover_color: #fff;
 // hover 导航栏颜色
 @hover_nav_color: #b8937f;
// 红色nav
.sub_nav {
  width: 100%;
  background-color: @theme_color;
  .container {
    max-width: 1380px;
    height: 30px;
    line-height: 30px;
    margin: 0 auto;
    position: relative; // clear: both;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .welcome {
      float: left;
      padding-right: 10px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: @font_color;
    }
    .login_register,
    .login_complete {
      height: 30px;
      line-height: 30px;
      float: left;
      a {
        padding-right: 5px;
        font-size: 14px;
        color: @font_color;
        &:hover {
          color: @hover_color;
        }
      }
      .logout {
        font-size: 14px;
        color: @font_color;
        &:hover {
          color: @hover_color;
        }
      }
    }
    nav {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      >span {
        height: 30px;
        line-height: 30px;
        padding: 0 30px 0 0;
        box-sizing: border-box;
        outline: none;
        a {
          font-size: 14px;
          color: @font_color;
          .iconfont {
            font-size: 25px;
            color: @font_color;
            vertical-align: middle;
          }
        }

        span {
          font-size: 14px;
          color: @font_color;
        }
      }

      .drop_down {
        position: relative;
      }

      .item a:hover {
        color: @hover_color;
      }

      .item a:hover i {
        color: @hover_color;
      }
    }
    .search_bar {
      width:200px;
      height: 20px;
      line-height: 20px;
      box-sizing: border-box;
      border-radius: 10px;
      position: relative;
      font-size: 14px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      overflow: hidden;
      .el-select{
        margin: 0;
        border: none;
        height: 20px;
        line-height: 20px;
        background-color: #fff;
        ::v-deep{
          .el-input{
              height: 20px;
              line-height: 20px;
            .el-input__inner{
              margin: 0;
              height: 20px;
              line-height: 20px;
              background-color: transparent;
              border: none;
              padding: 0 10px;
              font-size: 14px;
            }
            .el-input__suffix{
              height: 20px;
              line-height: 20px;
            }
            .el-input__icon{
              line-height: 20px;
              width: 20px;
            }
          }
        }
      }
      .search_input{
        margin: 0;
        padding: 0 16px;
        border: none;
        height: 20px;
        line-height: 20px;
        font-size: 14px;
      }
      .iconfont {
        cursor: pointer;
        font-size: 18px;
        color: #6d6659;
        margin: 0 6px 0 0;
      }
    }
  }
}
</style>