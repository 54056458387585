<template>
  <div class="menu-header" @mouseleave="pop = null">
  <!-- <div class="menu-header"> -->
    <div class="first">
      <router-link class="logo" to="/"><img src="../../assets/banner_logo.png" alt="logo" /></router-link>
      <ul class="nav">
        <li :class="{ active: current_nav.includes('home') }">
          <router-link to="/">首页</router-link>
        </li>
        <li :class="{ active: current_nav.includes('product') }" @mouseenter="pop = 'product'">
          <router-link :to="`/product/list/0-0-0-0-0/`">产品中心</router-link>
        </li>
        <!-- <li :class="{ active: current_nav.includes('bbs') }" @mouseenter="pop = ''">
          <router-link :to="`/bbs`">装修社区</router-link>
        </li> -->
        <li :class="{ active: current_nav.includes('design') || current_nav.includes('albums') }" @mouseenter="pop = 'design'">
          <router-link :to="`/albums/0-0-0-0-0-0-0-0/`">设计支持</router-link>
        </li>
        <li :class="{ active: current_nav.includes('store') || current_nav.includes('download') }" @mouseenter="pop = 'store'">
          <router-link :to="`/store/0-0-0`">经销支持</router-link>
        </li>
        <li :class="{ active: current_nav.includes('about') }" @mouseenter="pop = 'about'">
          <router-link :to="`/about/intro.html`">走进金舵</router-link>
        </li>
        <li :class="{ active: current_nav.includes('contact') }">
          <router-link :to="`/contact`">联系我们</router-link>
        </li>
        <template v-if="designer && designer.user_id" >
          <button @click="goDesign(4)">在线设计</button>
          <button @click="goDesign(5)">登岛设计园</button>
          <!-- <button @click="goDesign(14)">去设计圈</button> -->
        </template>
      </ul>
    </div>
    <div class="pop product" :class="{active:pop === 'product'}">
      <div class="wrap">
        <!-- 系列 -->
        <div class="param">
          <p class="param_title"><img class="Icon_class" src="@/assets/nav_series.png" alt="按系列分" />按系列分</p>
          <ul class="param_list">
            <li class="param_list_item" v-for="(vo, index) in series.slice(0,12)" :key="`series${index}`" >
              <router-link :to="getURL('series_id',vo.id)">{{ vo.name }}</router-link>
            </li>
            <li v-if="series.length > 12" class="param_list_item more">
              <router-link :to="`/product/list/0-0-0-0-0`">更多 <img src="https://resource.jinduo.com/upload/jinduo/image/20220819/01761ea1a77ef00829f2aacd868be8fa.png"></router-link>
            </li>
          </ul>
        </div>
        <!-- 品类 -->
        <div class="param" style="display: none;">
          <p class="param_title"><img class="Icon_class" src="@/assets/nav_categorys.png" alt="按产品类别分" />按产品类别分</p>
          <ul class="param_list">
            <li class="param_list_item" v-for="(vo, index) in categories.slice(0,12)" :key="`categories${index}`" >
              <router-link :to="getURL('cate_id', vo.id)" > {{ vo.name }} </router-link>
            </li>
            <li v-if="categories.length > 12" class="param_list_item more">
              <router-link :to="`/product/list/0-0-0-0-0`">更多 <img src="https://resource.jinduo.com/upload/jinduo/image/20220819/01761ea1a77ef00829f2aacd868be8fa.png"></router-link>
            </li>
          </ul>
        </div>
        <!-- 风格 -->
        <div class="param" style="display: none;">
          <p class="param_title"> <img class="Icon_class" src="@/assets/nav_styles.png" alt="按装修风格分" />按装修风格分 </p>
          <ul class="param_list">
            <li class="param_list_item" v-for="(vo, index) in styles.slice(0,12)" :key="`styles${index}`" >
              <router-link :to="getURL('style_id', vo.id)" > {{ vo.name }} </router-link>                    
            </li>
            <li v-if="styles.length > 12" class="param_list_item more">
              <router-link :to="`/product/list/0-0-0-0-0`">更多 <img src="https://resource.jinduo.com/upload/jinduo/image/20220819/01761ea1a77ef00829f2aacd868be8fa.png"></router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <nav class="pop design" :class="{active:pop === 'design'}">
      <p class="wrap">
        <router-link class="router" to="/albums/0-0-0-0-0-0-0-0/">
          <!-- <i class="iconfont">&#xe616;</i> -->
          <img class="d" src="@/assets/home/albums_g.png" alt="设计案例" />
          <img class="h" src="@/assets/home/albums.png" alt="设计案例"/>
          <span>设计案例</span>
        </router-link>
        <router-link class="router" to="/designers/0-0-1-0-0/">
          <!-- <i class="iconfont">&#xe612;</i> -->
          <img class="d" src="@/assets/home/designers_g.png" alt="找设计师" />
          <img class="h" src="@/assets/home/designers.png" alt="找设计师" />
          <span>找设计师</span>
        </router-link>
      </p>
    </nav>
    <nav class="pop about" :class="{active:pop === 'about'}">
      <p class="wrap">
        <router-link class="router" to="/about/intro.html">
          <i class="iconfont">&#xe71c;</i>
          <span>企业介绍</span>
        </router-link>
        <router-link class="router" to="/about/progress.html">
          <i class="iconfont">&#xe632;</i>
          <span>发展历程</span>
        </router-link>
        <router-link class="router" to="/about/honour.html">
          <i class="iconfont">&#xe68d;</i>
          <span>荣誉资质</span>
        </router-link>
        <router-link class="router" to="/about/news.html?type=401">
          <i class="iconfont">&#xe605;</i>
          <span>动态新闻</span>
        </router-link>
      </p>
    </nav>
    <nav class="pop store" :class="{active:pop === 'store'}">
      <p class="wrap">
        <router-link class="router" to="/store/0-0-0">
          <i class="iconfont">&#xead3;</i>
          <span>门店服务</span>
        </router-link>
        <router-link class="router" to="/download">
          <i class="iconfont">&#xe66c;</i>
          <span>经销专区</span>
        </router-link>
      </p>
    </nav>
  </div>
</template>

<script>
import {
  getIframeSrc
} from "@/api/designerCenter";
import { mapState } from "vuex";
export default {
  name: "topMenu",
  data() {
    return {
      pop: null,
    };
  },
  created() {
    console.log('created aliveParams', this.aliveParams);
  },
  mounted() {
    this.$nextTick(() => {
    });
  },
  computed: {
    ...mapState('user', ['current_nav', 'token','showConfirmModal','designer']),
    ...mapState('params', ['aliveParams']),
    series(){
      return this.aliveParams?.['series_classification']?.values || [];
    },
    styles(){
      return this.aliveParams?.['application_style_suggestion']?.values || [];
    },
    categories(){
      return this.aliveParams?.['product_category']?.values || [];
    },
  },
  methods: {          
    getURL(key, value){
      const keyMap = ['series_id','style_id','cate_id','space_id','spec_id']
      const query = keyMap.map((param,place)=>(param==key?value:'0')).join('-');
      // console.log('getURL query', query);
      const router = {
       path: `/product/list/${query}/`,
      }
      return  router
    },

    async goDesign(dest){
      if (this?.designer?.designer_detail?.id) {
        try {
          const res = await getIframeSrc({
            dest,
          });
          window.open(
            res.data,
            '_blank'
          )
        } catch (error) {
          console.log('error getIframeSrc', error)
          this.$message.error(error.message || error)
        }
      } else {
        this.$store.commit("user/SWITCH_LOGIN", !this.showConfirmModal);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.menu-header{
  width: 100%;
  position: relative;
  z-index: 10;
  margin: auto;
  background: #fff;
  a {
    color:#000000;
    font-size: 14px;
    display: block;
    &:focus,&:hover{
      span, i{
        color: #B7937F;
      }
    }
  }
  .first{
    width: 1200px;
    height: 80px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    .logo{
      width: fit-content;
      height: fit-content;
      box-sizing: border-box;
      img{
        max-width: 193px;
        max-height: 66px;
        width: auto;
        height: auto;
      }
    }
    ul.nav{
      display: inline-block;
      width: fit-content;
      height: 100%;
      >li{
        display: inline-block;
        height: 100%;
        margin: 0 1.5rem;
        box-sizing: border-box;
        position: relative;
        >a{
          display: inline-block;
          width: fit-content;
          height: 100%;
          line-height: 80px;
          text-align: center;
          font-size: 16px;
          color: #6E645A;
          &::after{
            content: '';
            width: 0;
            height: 3px;
            background: #b8937f;
            position: absolute;
            z-index: 1;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            transition-duration: 0.6s;
          }
        }
        &:hover >a::after,
        &.active >a::after{
          content: '';
          width: 100%;
          transition-duration: 0.6s;
        }
      }
      button{
        font-size:14px;
        border-radius: 5px;
        background-color: rgb(66, 67, 75);
        color: rgb(255, 255, 255);
        width: 100px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        display: inline-block;
        cursor: pointer;
        &:not(:first-of-type){
          margin: 0 0 0 20px;
        }
      }
    }
  }
}
.pop{
  position: absolute;
  z-index: -1;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0px;
  overflow: hidden;
  margin: auto;
  transition-property: all;
  transition-duration: 0.6s;
  background: #ffffffe6;
  box-shadow: #cccccc 0px 5px 5px, inset #f3f3f3 0px 10px 20px 0px;
  .wrap{
    width: 1200px;
    height: fit-content;
    opacity: 0;
    margin: auto;
    transition-property: all;
    transition-duration: 0.3s;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    a.router{
      padding: 25px 85px 17px;
      text-align: center;
      box-sizing: border-box;
      i.iconfont{
        display: block;
        color: #6e645a;
        font-size: 45px;
        padding-bottom: 5px;
        transition-duration: 0.3s;
      }
      img.d,img.h{
        width: 56px;
        height: 77px;
        display: block;
        object-fit: contain;
        box-sizing: border-box;
      }
      img.h{
        display: none;
      }
      span{
        display: block;
        margin: 5px 0 0 0;
        transition-duration: 0.3s;
      }
      &:hover{
        color: #B7937F;
        span, i.iconfont{
          color: #B7937F;
        }
        img{
          &.d{
            display: none;
          }
          &.h{
            display: block;
          }
        }
      }
    }
  }
  &.design .wrap a.router{
    padding: 20px 85px 17px;
  }
  &.product .wrap{
    padding: 30px 0 40px;
    display: flex;
    flex-flow: row;
    .param{
      width: 33%;
      padding: 0 48px 0 43px;
      &:nth-child(2){
        border-left: 1px #E0E1E6 solid;
        border-right: 1px #E0E1E6 solid;
      }
    }
    p {
      font-size:18px;
      margin:  0px 0px 30px 0;
      font-weight: 600;
      img{ vertical-align:middle; margin: 0 10px 2px 0;}
    } 
    ul{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px 44px;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      li{
        height: 40px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
        &:hover{
          color: #B7937F;
          a{
            color: #B7937F;
          }
        }
        &.more{
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          img{
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }
        }
      }
    }
  }
  &.active{
    height: 145px;
    &.product{
      height: 340px;
    }
    .wrap{
      opacity: 1;
    }
  }
}

</style>